var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    staticClass: "container"
  }, [_c('Header'), _c('c-box', {
    attrs: {
      "max-w": "1200",
      "mx": "auto",
      "mt": "30px"
    }
  }, [_c('Breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "20px"
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "10",
      "mb": "50"
    }
  }, [_c('c-box', {
    attrs: {
      "mx": "auto",
      "w": "800px"
    }
  }, [_c('c-tabs', [_c('c-tab-list', [_c('c-tab', [_c('c-text', [_vm._v("Detail Progress & Tracker")])], 1), _c('c-tab', [_c('c-text', [_vm._v("Feedback Ahli Gizi")])], 1)], 1), _c('c-tab-panels', [_c('c-tab-panel', {
    attrs: {
      "p": "30"
    }
  }, [_c('TabDetailProgress', {
    attrs: {
      "progress": _vm.progress
    }
  })], 1), _c('c-tab-panel', {
    attrs: {
      "p": "30"
    }
  }, [_c('TabFeedback')], 1)], 1)], 1)], 1)], 1)], 1), _c('Footer')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }