var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('CardStep', {
    attrs: {
      "current": _vm.currentStep,
      "total": 2,
      "is-prev": _vm.currentStep > 1,
      "is-next": _vm.currentStep < 2 + 1
    },
    on: {
      "prev": function prev($event) {
        return _vm.onPrev();
      },
      "next": function next($event) {
        return _vm.onNext();
      }
    },
    scopedSlots: _vm._u([_vm.currentStep === 2 ? {
      key: "next",
      fn: function fn() {
        return [_vm._v(" Submit ")];
      },
      proxy: true
    } : {
      key: "next",
      fn: function fn() {
        return [_vm._v(" Selanjutnya ")];
      },
      proxy: true
    }], null, true)
  }, [_vm.currentStep === 1 ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Identitas Ahli Gizi ")]), _c('c-form-control', {
    attrs: {
      "mt": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "for": "firstName"
    }
  }, [_vm._v(" Nama Depan ")]), _c('c-input', {
    attrs: {
      "type": "text",
      "name": "firstName",
      "value": _vm.firstName,
      "disabled": ""
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "for": "lastName"
    }
  }, [_vm._v(" Nama Belakang ")]), _c('c-input', {
    attrs: {
      "type": "text",
      "name": "lastName",
      "value": _vm.lastName,
      "disabled": ""
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "for": "title"
    }
  }, [_vm._v(" Gelar ")]), _c('c-input', {
    attrs: {
      "type": "text",
      "name": "title",
      "value": _vm.title
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mt": "20px",
      "is-required": ""
    }
  }, [_c('c-form-label', {
    attrs: {
      "for": "date"
    }
  }, [_vm._v(" Tanggal & waktu ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.date,
      expression: "date"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "borderrows": "4",
      "w": "full",
      "h": "10",
      "px": "4",
      "border-width": "1px",
      "border-color": "gray.300",
      "rounded": "md",
      "type": "date",
      "name": "date",
      "required": ""
    },
    domProps: {
      "value": _vm.date
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.date = $event.target.value;
      }
    }
  })], 1)], 1) : _vm._e(), _vm.currentStep === 2 ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Feedback Notes ")]), _c('c-form-control', {
    attrs: {
      "mt": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "for": "pola_makan"
    }
  }, [_vm._v(" Masukan untuk pola makan ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pola_makan,
      expression: "pola_makan"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "rows": "4",
      "w": "full",
      "p": "10px",
      "border-width": "1px",
      "border-color": "gray.300",
      "rounded": "md",
      "name": "pola_makan"
    },
    domProps: {
      "value": _vm.pola_makan
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.pola_makan = $event.target.value;
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "for": "aktivitas_harian"
    }
  }, [_vm._v(" Masukan untuk aktivitas harian (aktivitas fisik & olahraga) ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.aktivitas_harian,
      expression: "aktivitas_harian"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "rows": "4",
      "w": "full",
      "p": "10px",
      "border-width": "1px",
      "border-color": "gray.300",
      "rounded": "md",
      "name": "aktivitas_harian"
    },
    domProps: {
      "value": _vm.aktivitas_harian
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.aktivitas_harian = $event.target.value;
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "for": "gaya_hidup"
    }
  }, [_vm._v(" Masukan untuk gaya hidup lainnya ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.gaya_hidup,
      expression: "gaya_hidup"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "rows": "4",
      "w": "full",
      "p": "10px",
      "border-width": "1px",
      "border-color": "gray.300",
      "rounded": "md",
      "name": "gaya_hidup"
    },
    domProps: {
      "value": _vm.gaya_hidup
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.gaya_hidup = $event.target.value;
      }
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }