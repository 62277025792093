var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('CardStep', {
    attrs: {
      "current": _vm.currentStep,
      "total": _vm.totalSteps,
      "is-next": _vm.currentStep < _vm.totalSteps,
      "is-prev": _vm.currentStep > 1
    },
    on: {
      "next": _vm.nextStep,
      "prev": _vm.prevStep
    }
  }, [_vm.currentStep === 1 ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Identitas ")]), _c('QuizionaryDetail', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "30px",
      "data": _vm.groupedAnswer.identitas
    }
  })], 1) : _vm._e(), _vm.currentStep === 2 ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Antropometri (Ukuran & komposisi tubuh) ")]), _c('QuizionaryDetail', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "30px",
      "data": _vm.groupedAnswer.antropometri
    }
  })], 1) : _vm._e(), _vm.currentStep === 3 ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Pola Makan ")]), _c('QuizionaryDetail', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "30px",
      "data": _vm.groupedAnswer.pola_makan
    }
  })], 1) : _vm._e(), _vm.currentStep === 4 ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Perasaan & Pengetahuan ")]), _c('QuizionaryDetail', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "30px",
      "data": _vm.groupedAnswer.perasaan_pengetahuan
    }
  })], 1) : _vm._e(), _vm.currentStep === 5 ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Aktivitas Harian ")]), _c('QuizionaryDetail', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "30px",
      "data": _vm.groupedAnswer.aktivitas_harian
    }
  })], 1) : _vm._e(), _vm.currentStep === 6 ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Prosedur Medis ")]), _c('QuizionaryDetail', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "30px",
      "data": _vm.groupedAnswer.prosedur_medis
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }